var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"studio-list"}},[_c('base-material-card',{attrs:{"title":_vm.$t('list', { name: _vm.$tc('studio', 2) }),"icon":"$studio"}},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"10","md":"2"}},[_c('v-text-field',{staticClass:"ml-auto search",style:(_vm.$vuetify.breakpoint.xsOnly ? '' : 'max-width: 250px'),attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-magnify","label":"Buscar","hide-details":"","single-line":""},on:{"input":_vm.searchFilter},model:{value:(_vm.filters.search),callback:function ($$v) {_vm.$set(_vm.filters, "search", $$v)},expression:"filters.search"}})],1),_c('v-spacer'),(_vm.$store.getters['auth/isAdmin'])?_c('v-col',{attrs:{"cols":"2","align":"right"}},[_c('v-btn',{attrs:{"dark":""},on:{"click":function($event){return _vm.$router.push({ name: 'studioCreate' })}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("new", { name: _vm.$tc("studio") }))+" ")],1)],1):_vm._e(),(
          _vm.$store.getters['auth/getPlan']('mystudio') &&
            _vm.$store.getters['auth/isTattooer']
        )?_c('v-btn',{attrs:{"icon":"","fab":"","elevation":"0"},on:{"click":function($event){return _vm.$router.push({ name: 'StudioGuest' })}}},[_c('v-icon',{attrs:{"size":"36"}},[_vm._v("mdi-plus-circle")])],1):(_vm.$store.getters['auth/isTattooer'])?_c('v-btn',{attrs:{"icon":"","fab":"","elevation":"0"},on:{"click":function($event){return _vm.noPlan()}}},[_c('v-icon',{attrs:{"size":"36"}},[_vm._v("mdi-plus-circle")])],1):_vm._e()],1),_c('v-data-table',{attrs:{"footer-props":{
        'items-per-page-text': _vm.$vuetify.breakpoint.smAndDown
          ? ''
          : _vm.$t('items_per_page'),
      },"headers":_vm.headers,"items":_vm.items,"search":_vm.filters.search,"options":_vm.options,"server-items-length":_vm.totalStudios,"loading":_vm.loading,"sort-by":_vm.sortBy},on:{"update:search":function($event){return _vm.$set(_vm.filters, "search", $event)},"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"item.phone",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.phone || "-")+" ")]}},{key:"item.address",fn:function(ref){
      var item = ref.item;
return [(item.address)?_c('span',[_vm._v(_vm._s(item.address)+", ")]):_vm._e(),(item.postal_code)?_c('span',[_vm._v(_vm._s(item.postal_code)+", ")]):_vm._e(),(item.city)?_c('span',[_vm._v(_vm._s(item.city)+" ")]):_vm._e(),(!item.address && !item.postal_code && !item.city)?_c('span',[_vm._v(" - ")]):_vm._e()]}},{key:"item.country",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.country ? _vm.country(item.country) : "-")+" ")]}},{key:"item.state",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{staticStyle:{"color":"black"},attrs:{"color":_vm.statusStudio(item).color},domProps:{"textContent":_vm._s(
            _vm.$t(_vm.statusStudio(item).text, {
              date: _vm.$d(_vm.statusStudio(item).date, 'dayMonth'),
            })
          )}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('Configuration',{ref:("configuration-" + (item.id)),attrs:{"studio":item},on:{"update":_vm.fetchStudios}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"large":""}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-horizontal")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"cusList",on:{"click":function($event){return _vm.$router.push({ name: 'studioView', params: { id: item.id } })}}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"margin-top":"-4px !important"},attrs:{"small":""}},[_vm._v("$eye")])],1),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t("view", { name: _vm.$tc("studio") })))])])],1),(_vm.$store.getters['auth/isAdmin'])?_c('v-list-item',{staticClass:"cusList",on:{"click":function($event){return _vm.editStudio(item)}}},[_c('v-list-item-icon',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("$edit")])],1),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t("edit", { name: _vm.$tc("studio") })))])])],1):_vm._e(),(
                item.tattooer_studios &&
                  item.tattooer_studios.length &&
                  item.tattooer_studios[0].status === 'confirmed' &&
                  false
              )?_vm._l((_vm.types),function(type){return _c('v-list-item',{key:type,staticClass:"cusList",on:{"click":function($event){return _vm.formStudio(item, type)}}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"margin-top":"-5px !important","margin-left":"-1px !important"},attrs:{"small":""}},[_vm._v(" $request ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("tattooers.inscription"))+" "),(!type.name)?_c('span',[_vm._v(_vm._s(_vm.$tc(type)))]):_c('span',[_vm._v(_vm._s(_vm.$tc(type.name)))])])],1)],1)}):_vm._e(),(
                item.tattooer_studios &&
                  item.tattooer_studios.length &&
                  item.tattooer_studios[0].status === 'confirmed'
              )?_c('v-list-item',{on:{"click":function($event){_vm.$refs[("configuration-" + (item.id))].open()}}},[_c('v-list-item-icon',{staticClass:"mr-1 my-2"},[_c('v-icon',{staticStyle:{"margin-left":"-1px","margin-top":"-1.5px !important"},attrs:{"size":"17"}},[_vm._v(" mdi-cog-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("configurate", { name: _vm.$tc("studio") }))+" ")])],1)],1):_vm._e(),(
                item.tattooer_studios &&
                  item.tattooer_studios.length &&
                  item.tattooer_studios[0].status !== 'confirmed'
              )?_c('v-list-item',{staticClass:"cusList",on:{"click":function($event){return _vm.$router.push({
                  name: 'accept_tattooer_invite',
                  params: {
                    studio: item.id,
                    hash: _vm.hash,
                    tattooer: _vm.$store.state.auth.user.id,
                  },
                })}}},[_c('v-list-item-icon',[_c('v-icon',{staticStyle:{"margin-top":"-4px !important"},attrs:{"small":""}},[_vm._v("mdi-check")])],1),_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t("accept_invitation")))])])],1):_vm._e()],2)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }