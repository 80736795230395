<template>
  <v-container id="studio-list">
    <base-material-card
      :title="$t('list', { name: $tc('studio', 2) })"
      icon="$studio"
    >
      <v-row justify="start">
        <v-col cols="10" md="2">
          <v-text-field
            outlined
            dense
            v-model="filters.search"
            prepend-inner-icon="mdi-magnify"
            class="ml-auto search"
            label="Buscar"
            hide-details
            single-line
            :style="$vuetify.breakpoint.xsOnly ? '' : 'max-width: 250px'"
            @input="searchFilter"
          />
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="2" align="right" v-if="$store.getters['auth/isAdmin']">
          <v-btn dark @click="$router.push({ name: 'studioCreate' })">
            <v-icon>mdi-plus</v-icon>
            {{ $t("new", { name: $tc("studio") }) }}
          </v-btn>
        </v-col>
        <v-btn
          @click="$router.push({ name: 'StudioGuest' })"
          v-if="
            $store.getters['auth/getPlan']('mystudio') &&
              $store.getters['auth/isTattooer']
          "
          icon
          fab
          elevation="0"
        >
          <v-icon size="36">mdi-plus-circle</v-icon>
        </v-btn>

        <v-btn
          @click="noPlan()"
          v-else-if="$store.getters['auth/isTattooer']"
          icon
          fab
          elevation="0"
        >
          <v-icon size="36">mdi-plus-circle</v-icon>
        </v-btn>
      </v-row>

      <v-data-table
        :footer-props="{
          'items-per-page-text': $vuetify.breakpoint.smAndDown
            ? ''
            : $t('items_per_page'),
        }"
        :headers="headers"
        :items="items"
        :search.sync="filters.search"
        :options.sync="options"
        :server-items-length="totalStudios"
        :loading="loading"
        :sort-by.sync="sortBy"
      >
        <template v-slot:item.phone="{ item }">
          {{ item.user.phone || "-" }}
        </template>
        <template v-slot:item.address="{ item }">
          <span v-if="item.address">{{ item.address }}, </span>
          <span v-if="item.postal_code">{{ item.postal_code }}, </span>
          <span v-if="item.city">{{ item.city }} </span>

          <span v-if="!item.address && !item.postal_code && !item.city">
            -
          </span>
        </template>

        <template v-slot:item.country="{ item }">
          {{ item.country ? country(item.country) : "-" }}
        </template>
        <template v-slot:item.state="{ item }">
          <v-chip
            :color="statusStudio(item).color"
            style="color: black"
            v-text="
              $t(statusStudio(item).text, {
                date: $d(statusStudio(item).date, 'dayMonth'),
              })
            "
          />
        </template>
        <template v-slot:item.actions="{ item }">
          <Configuration
            :ref="`configuration-${item.id}`"
            :studio="item"
            @update="fetchStudios"
          />
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-on="on" v-bind="attrs" large
                >mdi-dots-horizontal</v-icon
              >
            </template>
            <v-list>
              <v-list-item
                class="cusList"
                @click="
                  $router.push({ name: 'studioView', params: { id: item.id } })
                "
              >
                <v-list-item-icon>
                  <v-icon small style="margin-top: -4px !important"
                    >$eye</v-icon
                  ></v-list-item-icon
                ><v-list-item-title>
                  <span>{{ $t("view", { name: $tc("studio") }) }}</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="$store.getters['auth/isAdmin']"
                class="cusList"
                @click="editStudio(item)"
              >
                <v-list-item-icon>
                  <v-icon class="mr-2">$edit</v-icon></v-list-item-icon
                ><v-list-item-title>
                  <span>{{
                    $t("edit", { name: $tc("studio") })
                  }}</span></v-list-item-title
                >
              </v-list-item>

              <template
                v-if="
                  item.tattooer_studios &&
                    item.tattooer_studios.length &&
                    item.tattooer_studios[0].status === 'confirmed' &&
                    false
                "
              >
                <v-list-item
                  v-for="type in types"
                  :key="type"
                  class="cusList"
                  @click="formStudio(item, type)"
                >
                  <v-list-item-icon>
                    <v-icon
                      small
                      style="
                        margin-top: -5px !important;
                        margin-left: -1px !important;
                      "
                    >
                      $request
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("tattooers.inscription") }}
                      <span v-if="!type.name">{{ $tc(type) }}</span>
                      <span v-else>{{ $tc(type.name) }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <v-list-item
                v-if="
                  item.tattooer_studios &&
                    item.tattooer_studios.length &&
                    item.tattooer_studios[0].status === 'confirmed'
                "
                @click="$refs[`configuration-${item.id}`].open()"
              >
                <v-list-item-icon class="mr-1 my-2">
                  <v-icon
                    style="margin-left: -1px; margin-top: -1.5px !important"
                    size="17"
                  >
                    mdi-cog-outline
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t("configurate", { name: $tc("studio") }) }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="
                  item.tattooer_studios &&
                    item.tattooer_studios.length &&
                    item.tattooer_studios[0].status !== 'confirmed'
                "
                class="cusList"
                @click="
                  $router.push({
                    name: 'accept_tattooer_invite',
                    params: {
                      studio: item.id,
                      hash: hash,
                      tattooer: $store.state.auth.user.id,
                    },
                  })
                "
              >
                <v-list-item-icon>
                  <v-icon small style="margin-top: -4px !important"
                    >mdi-check</v-icon
                  ></v-list-item-icon
                ><v-list-item-title>
                  <span>{{ $t("accept_invitation") }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { debounce } from "lodash";
import axios from "axios";

export default {
  name: "StudioList",
  data() {
    return {
      headers: [
        {
          text: this.$t("studios.name"),
          align: "center",
          sortable: false,
          value: "studio_name",
        },
        {
          text: this.$t("studios.address"),
          align: "center",
          sortable: false,
          value: "address",
        },

        {
          text: this.$t("studios.country"),
          align: "center",
          sortable: false,
          value: "country",
        },
        {
          text: this.$t("email"),
          align: "center",
          sortable: false,
          value: "user.email",
        },
        {
          text: this.$t("phone"),
          align: "center",
          sortable: false,
          value: "phone",
        },
        {
          text: this.$t("tattooers.state"),
          sortable: false,
          value: "state",
          align: "center",
        },
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      items: null,
      filters: {
        search: "",
      },
      hash: null,
      types: [],
      totalStudios: 0,
      sortBy: null,
      rowsPerPageItems: [10, 25, 50, 100, 200],
      options: {},
      loading: true,
      countries: [],
    };
  },
  components: {
    CloseDaysTattooer: () =>
      import("@/components/user/settings/profile/CloseDaysTattooer"),
    Configuration: () => import("@/components/studio/Configuration2"),
  },
  mounted() {
    this.loadCountry();
    if (this.$store.getters["auth/isTattooer"]) {
      this.filters.tattooer_id = this.$store.state.auth.user.id;
      if (this.$store.state.auth.user.types[0].name) {
        this.types = this.$store.state.auth.user.types;
      } else {
        this.types = this.$store.state.auth.user.types.map((q) => {
          switch (q) {
            case 1:
              return "tattooer";
            case 2:
              return "piercer";
            case 3:
              return "microblading";
            case 4:
              return "laser";
            default:
              return;
          }
        });
      }
      //console.log(this.types);
    }
    this.getHash();
    this.fetchStudios();
  },
  watch: {
    options: {
      handler() {
        this.fetchStudios();
      },
      deep: true,
    },
  },
  methods: {
    noPlan() {
      this.$confirm(
        "Para poder añadir un estudio debes mejorar tu plan actual"
      ).then(() => {
        this.$router.push({
          name: "UserSettings",
          params: { type: "myplan", calendar: true },
        });
      });
    },
    ...mapActions("auth", ["getUserHash"]),
    getHash() {
      this.getUserHash().then((data) => {
        console.log(data.hash);
        this.hash = data.hash;
      });
    },
    loadCountry() {
      this.url = `https://restcountries.com/v2/all`;
      axios
        .get(this.url)
        .then((response) => {
          this.countries = response.data;
        })
        .catch((errorResponse) => {
          //console.log(errorResponse);
        });
    },
    country(code) {
      let country = this.countries.find((x) => x.alpha2Code === code);
      if (country)
        return (
          country.translations[this.$store.getters["app/language"]] ||
          country.name
        );
      //return code;
    },
    statusStudio(tattooer) {
      let activeTattooerStudios = tattooer.tattooer_studios
        ? tattooer.tattooer_studios[0] || null
        : null;

      if (
        activeTattooerStudios &&
        activeTattooerStudios.status !== "archived"
      ) {
        let now = new Date();
        let from = new Date(activeTattooerStudios.from);
        let to = activeTattooerStudios.to
          ? new Date(activeTattooerStudios.to)
          : null;
        if (activeTattooerStudios.status !== "confirmed")
          return {
            text: "tattooers.states.invited",
            color: "green1",
            date: from,
            archivar: true,
          };
        if (now.getTime() >= from.getTime()) {
          if (to) {
            return {
              text: "tattooers.states.active_until",
              color: "primary",
              date: to,
              archivar: true,
            };
          }
          return {
            text: "tattooers.states.active",
            color: "primary",
            archivar: true,
          };
        } else
          return {
            text: "tattooers.states.will_active",
            color: "login_error",
            date: from,
            archivar: true,
          };
      } else {
        return {
          text: "tattooers.states.unactive",
          color: "gris1",
          archivar: false,
        };
      }

      return activeTattooerStudios;
    },
    formStudio(studio, type) {
      let has_time = false;
      studio.timetable.forEach((x) => {
        if (x.length > 0) has_time = true;
      });
      if (has_time) {
        this.$router.push({
          name: "customer_inscription_studio",
          params: {
            token: studio.token,
            tattooer_id: this.$store.state.auth.user.tattooer.id,
            artist: type,
          },
        });
      } else {
        this.$alert(this.$t("studio_selected_no_time"));
      }
    },
    ...mapActions("studios", ["getStudios"]),
    editStudio(item) {
      this.$router.push({
        name: "studioEdit",
        params: {
          id: item.id,
        },
      });
    },

    fetchStudios() {
      this.loading = true;
      this.getStudios({
        pagination: this.options,
        filters: this.filters,
      }).then((data) => {
        this.items = data.data;
        this.totalStudios = data.total;
        this.loading = false;
        console.log(this.items);
      });
    },
    searchFilter: debounce(function(val) {
      this.fetchStudios();
    }, 1000),
  },
};
</script>
<style lang="sass">
.cusList
  height: 30px
  .v-list-item__icon
    margin-right: 5px !important
.v-data-table__mobile-row__cell
  max-width: 175px !important
@media (min-width: 700px)
  #studio-list
    .v-data-table
      td:first-child, td:nth-child(2)
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        max-width: 115px
        padding: 5px !important
      td:nth-child(4)
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        min-width: 90px
        max-width: 100px
        padding: 5px !important
      td:nth-child(6)
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden

        padding: 0px !important
      td:nth-child(5)
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        min-width: 10px
        max-width: 80px
        padding: 0px !important

      td:last-child
        min-width: 60px
        padding: 0px !important
      td:nth-child(3)
        white-space: nowrap
        text-overflow: ellipsis
        overflow: hidden
        min-width: 10px
        max-width: 179px
        padding: 5px !important
</style>
